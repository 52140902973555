import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { requestDocumentTypes } from 'actions/exports';
import SelectWrapper from './SelectWrapper';

const SelectDocumentTypes = (props) => {
  const { documentTypes, dispatch, onChange, value } = props;
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!init && (!documentTypes || documentTypes.length === 0)) {
      setInit(true);
      dispatch(requestDocumentTypes());
    }
  }, [documentTypes, dispatch, init]);

  // Automatically select the only option if there's exactly one
  useEffect(() => {
    if (documentTypes && documentTypes.length === 1) {
      onChange(documentTypes[0].id);
    }
  }, [documentTypes, onChange]);

  return (
    <div className="select select--grade">
      <label className="py-2 block text-sm font-medium text-gray-700">
        Dokumententyp (Vorlage)
      </label>
      <SelectWrapper>
        <select
          onChange={(e) => onChange(parseInt(e.target.value))}
          value={value || ""}
          className="shadow-sm block w-full border-gray-300 rounded-md outline-none border appearance-none"
        >
          <option value="" disabled>
            Auswählen...
          </option>
          {documentTypes
            .sort((t1, t2) => (t1.name > t2.name ? 1 : -1))
            .map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
        </select>
      </SelectWrapper>
    </div>
  );
};

export default connect((state) => {
  const documentTypes = state.entities && state.entities.document_types
    ? Object.keys(state.entities.document_types).map(
        (k) => state.entities.document_types[k]
      )
    : [];

  return {
    documentTypes,
  };
})(SelectDocumentTypes);
