import { requestExports } from 'actions/exports'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

function useInterval(callback = () => { }, delay = 1000) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id)
  }, [delay])
}


const ExportsList = (props) => {

  const { exports, dispatch } = props

  useInterval(() => {
    props.dispatch(requestExports())
  }, 1000 * 5)

  useEffect(() => {
    if (!exports) {
      dispatch(requestExports())
    }
  }, [exports, dispatch])
  
  if (!props.exports) {
    return <div className="p-8 bg-gray-100 h-full flex">
      <div className="my-auto w-full text-center text-gray-500">
        Es wurden noch keine Dokumente generiert.
      </div>
    </div>;
  }
  
  return (
    <div className="w-full h-full flex flex-col p-8 bg-gray-100 overflow-y-scroll">
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th colSpan="2" scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 pr-4 sm:pr-6">
                      Dokumentenstapel & Download
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {props.exports.length === 0 && (
                    <tr>
                      <td colSpan="2" className="text-center text-gray-500 py-4">
                        Es wurden noch keine Dokumente generiert.
                      </td>
                    </tr>
                  )}
                  {props.exports.reverse().map((exp, index) => {
                    const createdAt = exp.created_at.split("T");
                    return (
                      <tr key={index}>
                        <td className="px-3 py-3 text-sm text-gray-500">
                          <div className="text-gray-900 font-medium">
                            {exp.document_type?.name} - {exp.group?.name}
                          </div>
                          <div className="text-gray-400 text-xs">
                            {exp.document_title} &bull; {exp.document_year} &bull; {exp.document_handout_date?.split("T")[0]?.split("-").reverse().join(".")}
                          </div>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          {exp.status === 'PENDING' && (
                            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                              <span><i className="far fa-hourglass" /> Ausstehend</span>
                            </p>
                          )}
                          {exp.status === 'RUNNING' && (
                            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                              <span><i className="far fa-circle-notch fa-spin" /> In Arbeit</span>
                            </p>
                          )}
                          {exp.status === 'FAILED' && (
                            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                              <span><i className="far fa-exclamation-triangle" /> Fehler</span>
                            </p>
                          )}
                          {exp.status === 'ARCHIVED' && (
                            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-400">
                              <span><i className="far fa-archive" /> Archiviert</span>
                            </p>
                          )}
                          {exp.status === 'COMPLETED' && (
                            <div className="block">
                              <a
                                href={`${window.location.origin.replace('.', '-api.')}/api/v1/exports/${exp.id}/document/`}
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-600 hover:text-blue-900"
                              >
                                <i className="far fa-download" />&nbsp; Download
                              </a>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state) => {
  const exports = state.entities.exports
    ? Object.keys(state.entities.exports).map(k => state.entities.exports[k])
    : null
  return {
    exports,
  }
})(ExportsList)