import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { postExports, requestExports } from 'actions/exports';
import { requestGroups } from 'actions/groups';
import Input from '../components/form/Input';
import SelectDocumentTypes from '../components/form/SelectDocumentTypes';
import Switch from '../components/form/Switch';

const ExportsForm = (props) => {
  const [group_ids, setSelectedGroupIds] = useState([]);

  const [document_type, setDocumentTypeId] = useState();
  const [document_title, setDocumentTitle] = useState("");
  const [document_subtitle, setDocumentSubtitle] = useState("");
  const [document_handout_date, setDocumentHandoutDate] = useState("");
  const [document_year, setDocumentYear] = useState("");

  const { groups, dispatch } = props;

  useEffect(() => {
    if (!groups) {
      dispatch(requestGroups());
    }
  }, [groups, dispatch]);

  if (!groups) {
    return <div className="block pt-32 text-center">Inhalte werden geladen.</div>;
  }

  // Berechne das aktuelle und das vorherige Jahr
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  // Erzeuge den dynamischen Placeholder-Text
  const placeholderTextYear = `Bsp: „${previousYear} / ${currentYear}“`;
  // Erzeuge den dynamischen Placeholder-Text für das Ausgabedatum
  const placeholderTextHandout = `Bsp: „21. Juli ${previousYear}“`;

  const countIdsInGroupIds = (ids) => {
    return group_ids.reduce((sum, id) => {
      if (ids.includes(id)) {
        sum++;
      }
      return sum;
    }, 0);
  };

  const onChangeAllGroups = (ids) => {
    const idsInGroupIds = countIdsInGroupIds(ids);
    if (idsInGroupIds === ids.length) {
      // Entferne alle betroffenen IDs aus group_ids
      setSelectedGroupIds(group_ids.filter(id => !ids.includes(id)));
    } else {
      // Füge alle fehlenden IDs hinzu
      setSelectedGroupIds([...new Set([...group_ids, ...ids])]);
    }
  };

  const onChangeGroup = (id) => {
    setSelectedGroupIds(
      group_ids.includes(id)
        ? group_ids.filter(sid => sid !== id)
        : [...group_ids, id]
    );
  };

  const onClickStartExport = () => {

    const exports = {
      group_ids,
      document_type,
      document_title,
      document_subtitle,
      document_year,
      document_handout_date,
    };

    dispatch(postExports(exports))
      .then(() => dispatch(requestExports()));

    setSelectedGroupIds([]);
  };

  const valid =
    group_ids.length > 0 &&
    document_type &&
    document_title && document_title.length > 0 &&
    document_year && document_year.length > 0 &&
    document_handout_date && document_handout_date.length > 0;

  return <div className="w-full h-full flex flex-col bg-white">
    <div className="md:flex md:items-center md:justify-between px-8 py-5 bg-white shadow-lg z-10 border-b border-gray-100">
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate">
          Zeugnisse exportieren (PDF-Dokumente)
        </h2>
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        <div className="">
          <button
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:text-blue-100 disabled:bg-blue-400"
            onClick={onClickStartExport}
            disabled={!valid}
          >
            Exportieren
          </button>
        </div>
      </div>
    </div>
    <div className="overflow-y-scroll">
      <div className="p-8">
        <div className="mb-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Dokumentinformationen</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Wählen Sie die Vorlage, den Titel, das Ausgabedatum und das Schuljahr aus.
          </p>
        </div>
        <div className="grid grid-cols-2 gap-x-8 gap-y-6">
          <SelectDocumentTypes
            value={document_type} onChange={setDocumentTypeId}
            tabIndex="0"
          />
          <Input
            label="Titel"
            placeholder="Bsp: „Zeugnis“"
            tabIndex="1"
            value={document_title}
            onChange={setDocumentTitle}
          />
          <Input
            label="Schuljahr"
            placeholder={placeholderTextYear}
            tabIndex="3"
            value={document_year}
            onChange={setDocumentYear}
          />
          <Input
            label="Ausgabedatum"
            placeholder={placeholderTextHandout}
            tabIndex="4"
            value={document_handout_date}
            onChange={setDocumentHandoutDate}
          />
        </div>
      </div>

      <div className="lower-part p-8 border-t border-gray-200">
        <div className="mb-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Klasse(n) auswählen</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Wählen Sie die Klasse(n) aus, für die Zeugnisse exportiert werden sollen.
          </p>
        </div>
        {props.groups.map((groupsByGrade, index) => {
          const ids = groupsByGrade.map(g => g.id);
          return (
            <div className="py-4 border-t border-gray-200" key={index}>

              <h4 className="text-md leading-6 font-medium text-gray-700 mb-2">
                Jahrgang {groupsByGrade[0].grade.name}
                <button
                  type="button"
                  className="cursor-pointer"
                  onClick={() => {
                    onChangeAllGroups(ids);
                  }}
                >
                  <span className="pl-4 text-sm bg-white font-medium text-blue-600">
                    {countIdsInGroupIds(ids) === ids.length ? 'Auswahl aufheben' : 'Alle Klassen auswählen'}
                  </span>
                </button>
              </h4>

              <div className="grid grid-cols-4 gap-2" key={index}>
                {groupsByGrade.map((group, index) =>
                  <Switch
                    key={index}
                    info={group.name}
                    value={group_ids.includes(group.id)}
                    onChange={() => onChangeGroup(group.id)}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </div>;
};

export default connect((state) => {

  const allGroups = state.entities.groups
    ? Object.keys(state.entities.groups).map(k => state.entities.groups[k])
    : null;

  let groups;
  if (allGroups) {
    groups = allGroups.reduce((acc, group) => {
      const grade = group.grade;
      const key = grade.id - 1;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(group);
      return acc;
    }, []);

    groups = Object.keys(groups).map(k => groups[k]);
  }
  return {
    allGroups,
    groups,
  };
})(ExportsForm);